'use client'

import { useState } from 'react'

import Button from '@/components/Links/Button/Button'

import PrimaryCheck from '@/icons/blue-check.svg'
import Check from '@/icons/check-icon.svg'
import Cross from '@/icons/orange-cross.svg'
import ChevronDown from '@/icons/chevron-down.svg'

import styles from './Pricing.module.scss'
import classNames from 'classnames/bind'
import AnimateHeight from 'react-animate-height'

const classes = classNames.bind(styles)

export default ({ config, bulkMessage }) => {
    // Destructure the config.
    const { packages, features, sub_sections = [] } = config
    const small = packages.length > 3

    // Create a state for controlling the open sub section, only one can be open at once.
    const [openSubSection, setOpenSubSection] = useState(null)

    if (packages.length == 1) {
        return <SinglePackagePricing packageConfig={packages[0]} features={features} subSections={sub_sections} />
    }

    return (
        <div className={classes('pricing-table-wrapper')}>
            <table className={classes('pricing-table')}>
                <thead>
                    <tr className={classes('package-header-row')}>
                        <th className={classes('compare-text-cell')}>
                            Compare <br /> plans and features
                        </th>
                        {packages.map((column, i) => (
                            <th key={i} className={classes('package-header-cell', { small })}>
                                <PackageTitle {...column} small={small} />
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <PricingTableBody features={features} packages={packages} />
                </tbody>
                {sub_sections.map((section) => (
                    <SubsectionWrap
                        key={section.id}
                        packages={packages}
                        openSubSection={openSubSection}
                        setOpenSubSection={setOpenSubSection}
                        {...section}
                    />
                ))}
            </table>
            <div
                className={classes('bulk-message')}
                onClick={() => {
                    try {
                        zE('messenger', 'open')
                    } catch (e) {}
                }}
            >
                {bulkMessage ? bulkMessage : 'Need multiple tax returns? Get in touch for a personalised discount!'}
            </div>
        </div>
    )
}

const PackageTitle = ({ title, primaryText, secondaryText, buttonText, focus = false }) => {
    return (
        <div className={classes('package-header-content')}>
            <div className={classes('package-title-wrap')}>
                <div className={classes('package-subtitle')}>{title}</div>
                <div className={classes('package-text-wrapper')}>
                    <span className={classes('package-primary-text')}>{primaryText}</span>
                    <div className={classes('package-secondary-text-wrapper')}>
                        <span className={classes('package-secondary-text')}>
                            <span>/</span>
                            {secondaryText}
                        </span>
                        <span className={classes('package-vat')}>inc. VAT</span>
                    </div>
                </div>
            </div>
            <Button
                text={buttonText}
                type={`${focus ? 'primary' : 'blue-secondary'} square`}
                extraClasses={classes('button')}
                url="https://intro.taxd.co.uk/"
                newTab
            />
        </div>
    )
}

const SubsectionWrap = ({ id, title, features, packages, openSubSection, setOpenSubSection }) => {
    const matchingSection = openSubSection == id
    return (
        <tbody className={classes('sub-section-body')}>
            <tr className={classes('subsection-row')}>
                <td colSpan={packages.length + 1} style={{ padding: 0 }}>
                    <div className={classes('subsection-wrap')}>
                        <div
                            className={classes('subsection-title-wrap', { open: matchingSection })}
                            onClick={() => setOpenSubSection(matchingSection ? null : id)}
                        >
                            <span className={classes('subsection-title')}>{title}</span>
                            <ChevronDown className={classes('chevron', { open: matchingSection })} />
                        </div>
                        <AnimateHeight height={matchingSection ? 'auto' : 0} duration={200} animateOpacity>
                            <table className={classes('pricing-table')}>
                                <tbody>
                                    <PricingTableBody features={features} packages={packages} />
                                </tbody>
                            </table>
                        </AnimateHeight>
                    </div>
                </td>
            </tr>
        </tbody>
    )
}

const PricingTableBody = ({ features, packages }) => {
    // Loop through all the features in the particular table
    return features.map((feature, i) => {
        // Create an array of cells that we'll then show.
        // The first cell is the title of the feature.
        let cells = [
            <td key={`${feature.id}-title`} className={classes('feature-title-cell')}>
                <div className={classes('feature-title-wrapper')}>
                    <span className={classes('feature-title')}>{feature.title}</span>
                    {feature.sub_text && <span className={classes('feature-subtext')}>{feature.sub_text}</span>}
                </div>
            </td>,
        ]
        // Loop through all the packages and create a cell for each one.
        for (let i = 0; i < packages.length; i++) {
            cells.push(
                // Pass information about the feature, and the package's value for that feature.
                // Also include the 'focus' property if it's the package we want to push.
                <TableCell
                    key={`${feature.id}-${packages[i].id}-${i}`}
                    feature={feature}
                    value={packages[i]?.features?.[feature.id]}
                    focus={packages[i]['focus']}
                />
            )
        }
        // Return the row with the cells.
        return <tr key={`${feature.id}-${i}`}>{cells}</tr>
    })
}

const TableCell = ({ feature, value, focus = false }) => {
    const valueToUse = value != null ? value : feature?.default
    return (
        <td>
            <div className={classes('table-cell-wrapper')}>
                {
                    {
                        Boolean: <BooleanValue value={valueToUse} focus={focus} />,
                        Price: <PriceValue value={valueToUse} />,
                    }[feature.type]
                }
            </div>
        </td>
    )
}

const BooleanValue = ({ value, focus }) => {
    if (focus && value) {
        return <PrimaryCheck />
    } else if (value) {
        return <Check />
    }
    return <Cross />
}

// value is an object with 'price' and 'previous' properties.
const PriceValue = ({ value }) => {
    if (value) {
        return (
            <div className={classes('price-wrap')}>
                <span className={classes('price', { discounted: !!value.discounted || value.price == '£0' })}>
                    {value.price}
                </span>
                {value.discounted && <span className={classes('price', 'previous')}>{value.discounted}</span>}
            </div>
        )
    }
    return <Cross />
}

const SinglePackagePricing = ({ packageConfig, features, subSections }) => {
    const { title, primaryText, secondaryText, buttonText, focus } = packageConfig
    // Create a state for controlling the open sub section, only one can be open at once.
    const [openSubSection, setOpenSubSection] = useState(null)

    return (
        <div className={classes('single-package-wrapper')}>
            <div className={classes('graphic')} src={'/images/plan-benefits.png'} />
            <div className={classes('single-package-content')}>
                <div className={classes('package-header-content', 'single')}>
                    <div className={classes('package-title-wrap')}>
                        <div className={classes('package-subtitle')}>{title}</div>
                        <div className={classes('package-text-wrapper')}>
                            <span className={classes('package-primary-text')}>{primaryText}</span>
                            <div className={classes('package-secondary-text-wrapper')}>
                                <span className={classes('package-secondary-text')}>
                                    <span>/</span>
                                    {secondaryText}
                                </span>
                                <span className={classes('package-vat')}>inc. VAT</span>
                            </div>
                        </div>
                    </div>
                    <Button
                        text={buttonText}
                        type={classes('primary', 'square')}
                        extraClasses={classes('button')}
                        url="https://intro.taxd.co.uk/"
                        newTab
                    />
                </div>
                <div className={classes('list-wrapper')}>
                    <div className={classes('benefits-wrapper')}>
                        {features.map((feature, i) => (
                            <div key={i} className={classes('features-wrapper')}>
                                <Check className={classes('single-check')} />
                                <span className={classes('feature-title')}>{feature.title}</span>
                            </div>
                        ))}
                    </div>
                    {subSections.map((section) => (
                        <div key={section.id} className={classes('subsection-wrap')}>
                            <div
                                className={classes('subsection-title-wrap', { open: openSubSection == section.id })}
                                onClick={() => setOpenSubSection(openSubSection == section.id ? null : section.id)}
                            >
                                <span className={classes('subsection-title')}>{section.title}</span>
                                <ChevronDown className={classes('chevron', { open: openSubSection == section.id })} />
                            </div>

                            <AnimateHeight
                                height={openSubSection == section.id ? 'auto' : 0}
                                duration={200}
                                animateOpacity
                            >
                                <div className={classes('benefits-wrapper', 'single')}>
                                    {section.features.map((feature, i) => {
                                        return (
                                            <div key={i} className={classes('features-wrapper', 'spread')}>
                                                <span className={classes('feature-title')}>{feature.title}</span>
                                                <PriceValue
                                                    value={{ price: packageConfig.features[feature.id].price }}
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                            </AnimateHeight>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
