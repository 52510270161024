'use client'

import styles from './ContactSection.module.scss'
import classNames from 'classnames/bind'
import Title from '@/components/Elements/Title/Title'
import SectionWrap from '@/components/Layout/SectionWrap/SectionWrap'
import { useRef, useState } from 'react'

import useIntersectionObserver from '@/components/Helpers/UseIntersectionObserver/UseIntersectionObserver'
import Map from '@/icons/map-pin.svg'
import Mail from '@/icons/mail.svg'
import Phone from '@/icons/phone.svg'

const classes = classNames.bind(styles)

const ContactSection = ({ attributes, index }) => {
    const { title, subtitle, description } = attributes
    const sectionRef = useRef(null)

    useIntersectionObserver({
        refs: [sectionRef],
        callback: ({ isIntersecting }) => {
            if (isIntersecting) {
                sectionRef.current.classList.add(classes('enter'))
            }
        },
        options: {
            // a threshold of 0 will trigger our IntersectionObserver at the
            // base of the element – a threshold of 1 would trigger at the top.
            threshold: [0.2],
        },
    })

    return (
        <SectionWrap className={classes('section-wrap')} ref={sectionRef} id="contact-us">
            {attributes.title && (
                <Title
                    title={title}
                    description={description}
                    subtitle={subtitle}
                    index={index}
                    gradient={index == 0}
                    className={classes('section-title')}
                    variant={'h1'}
                />
            )}
            <ContactForm />
        </SectionWrap>
    )
}
export default ContactSection

const ContactForm = () => {
    const form = useRef(null)
    const firstNameRef = useRef(null)
    const lastNameRef = useRef(null)
    const emailRef = useRef(null)
    const phoneRef = useRef(null)
    const messageRef = useRef(null)

    const [sent, setSent] = useState(false)

    return (
        <SectionWrap className={classes('wrapper')}>
            <span className={classes('contact-title')}>Get in touch with our friendly team</span>
            <div className={classes('content-wrapper')}>
                <form
                    className={classes('contact-form')}
                    onSubmit={async (e) => {
                        e.preventDefault()

                        if (sent) return

                        let body = {
                            firstName: firstNameRef.current.value,
                            lastName: lastNameRef.current.value,
                            email: emailRef.current.value,
                            phone: phoneRef.current.value,
                            message: messageRef.current.value,
                        }

                        if (body.firstName === '' || body.email === '' || body.subject === '' || body.message === '') {
                            alert('Please fill in all required fields.')
                            return
                        }

                        const fetchAPI = (await import('@/utils/strapiApi.js')).default
                        const response = await fetchAPI(
                            '/contact-form',
                            {},
                            {
                                method: 'POST',
                                body: JSON.stringify(body),
                            }
                        )

                        if (response.success) {
                            setSent(true)
                            return
                        }
                        alert(response.message)
                    }}
                    ref={form}
                >
                    {!sent && (
                        <>
                            <div className={classes('grid')}>
                                <div className={classes('form-group')}>
                                    <label className={classes('label')} htmlFor="firstName">
                                        First name<span className={classes('asterisk')}>*</span>
                                    </label>
                                    <input
                                        className={classes('input')}
                                        type="text"
                                        id="firstName"
                                        placeholder="Joe"
                                        required={true}
                                        ref={firstNameRef}
                                    />
                                </div>
                                <div className={classes('form-group')}>
                                    <label className={classes('label')} htmlFor="lastName">
                                        Last name
                                    </label>
                                    <input
                                        className={classes('input')}
                                        type="text"
                                        id="lastName"
                                        placeholder="Smith"
                                        ref={lastNameRef}
                                    />
                                </div>
                                <div className={classes('form-group')}>
                                    <label className={classes('label')} htmlFor="email">
                                        Email address<span className={classes('asterisk')}>*</span>
                                    </label>
                                    <input
                                        className={classes('input')}
                                        type="email"
                                        id="email"
                                        placeholder="joe.smith@example.com"
                                        required={true}
                                        ref={emailRef}
                                    />
                                </div>
                                <div className={classes('form-group')}>
                                    <label className={classes('label')} htmlFor="phone">
                                        Phone
                                    </label>
                                    <input
                                        className={classes('input')}
                                        type="number"
                                        id="phone"
                                        placeholder="+447415103842"
                                        ref={phoneRef}
                                    />
                                </div>
                                <div className={classes('form-group', 'message')}>
                                    <label className={classes('label')} htmlFor="message">
                                        Message<span className={classes('asterisk')}>*</span>
                                    </label>
                                    <textarea
                                        className={classes('input')}
                                        type="text"
                                        id="message"
                                        placeholder="Your message..."
                                        required={true}
                                        ref={messageRef}
                                    />
                                </div>
                            </div>
                            <button className={classes('submit')}>Send form</button>
                        </>
                    )}
                    {sent && (
                        <div className={classes('info-item')}>
                            <span className={classes('label')}>Thank you for contacting us!</span>
                            <span className={classes('description')}>We will get back to you as soon as possible.</span>
                        </div>
                    )}
                </form>
                <div className={classes('content')}>
                    <InfoBlock
                        label={'Our address'}
                        description={`Tax'd Ltd registered office is at 41 Southgate Street, Winchester, England, SO23 9EH`}
                    >
                        <Map />
                    </InfoBlock>
                    <InfoBlock
                        label={'Live chat'}
                        description={
                            <>
                                Our live chat is the best way to get in touch,{' '}
                                <span onClick={() => zE('messenger', 'open')}>click here</span> to open the chat.
                            </>
                        }
                    >
                        <Mail />
                    </InfoBlock>
                    <InfoBlock label={'Phone'} description={`03333 050499`}>
                        <Phone />
                    </InfoBlock>
                </div>
            </div>
        </SectionWrap>
    )
}

const InfoBlock = ({ children, label, description }) => (
    <div className={classes('info-block')}>
        <div className={classes('svg-wrap')}>{children}</div>
        <div className={classes('info-item')}>
            <span className={classes('label')}>{label}</span>
            <span className={classes('description')}>{description}</span>
        </div>
    </div>
)
