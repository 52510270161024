'use client'

import { useEffect, useState } from 'react'
import { getCookie, setCookie, getValue } from '@/utils/index.js'

import BlueCookie from '@/icons/blue-cookie.svg'
import Toggle from '@/components/Elements/Toggle/Toggle'
import Button from '@/components/Links/Button/Button'

import styles from './CookieConsent.module.scss'
import classNames from 'classnames/bind'
const classes = classNames.bind(styles)

const CookieConsent = () => {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT !== 'prod') return null
    const [show, setShow] = useState(false)

    const [loaded, setLoaded] = useState(false)
    const [analytics, setAnalytics] = useState(false)
    const [personalisation, setPersonalisation] = useState(false)
    const [marketing, setMarketing] = useState(false)

    useEffect(() => {
        const consentCookie = JSON.parse(getCookie('CookieConsent') || '{}')

        setAnalytics(getValue(consentCookie, 'analytics', false))
        setPersonalisation(getValue(consentCookie, 'personalisation', false))
        setMarketing(getValue(consentCookie, 'marketing', false))

        setLoaded(true)

        setTimeout(() => {
            const consent = getCookie('CookieConsent')
            if (!consent) {
                setShow(true)
            }
        }, 50)
    }, [])

    useEffect(() => {
        if (!loaded) return
        const consent = analytics ? 'granted' : 'denied'

        gtag('consent', 'update', {
            analytics_storage: consent,
        })
        dataLayer.push({
            event: 'cookie_consent_update',
        })
    }, [analytics])

    useEffect(() => {
        if (!loaded) return
        const consent = personalisation ? 'granted' : 'denied'

        gtag('consent', 'update', {
            functionality_storage: consent,
            personalization_storage: consent,
        })
        dataLayer.push({
            event: 'cookie_consent_update',
        })
    }, [personalisation])

    useEffect(() => {
        if (!loaded) return
        const consent = marketing ? 'granted' : 'denied'

        gtag('consent', 'update', {
            ad_personalization: consent,
            ad_storage: consent,
            ad_user_data: consent,
        })
        dataLayer.push({
            event: 'cookie_consent_update',
        })
    }, [marketing])

    const savePreferences = (analyticsValue, personalisationValue, marketingValue) => {
        setCookie(
            'CookieConsent',
            JSON.stringify({
                analytics: analyticsValue,
                personalisation: personalisationValue,
                marketing: marketingValue,
            }),
            86400000 * 30,
            false
        )
        dataLayer.push({
            event: 'cookie_consent_update',
        })
    }

    if (!show) return null

    return (
        <div className={classes('overlay')}>
            <div className={classes('popup')}>
                <div className={classes('content')}>
                    <div className={classes('icon-wrapper')}>
                        <BlueCookie className={classes('icon')} />
                    </div>
                    <div className={classes('content-wrapper')}>
                        <span className={classes('title')}>
                            To optimise your experience with us, we collect cookies.
                        </span>
                        <p>
                            Some cookies are necessary for our website to work, and others we collect cookies to analyse
                            our website traffic and performance; we never collect any personal data here and we only use
                            this information to improve our site.
                        </p>
                        <p className={classes('italic')}>
                            For full details, please take a look at our <a href="/privacy-policy">Privacy Policy</a>.
                        </p>
                    </div>
                </div>
                <div className={classes('controls')}>
                    <div className={classes('toggles')}>
                        <div className={classes('toggle-wrapper')}>
                            <Toggle toggleName={'necessary'} value={true} disabled />
                            <span>Necessary</span>
                        </div>
                        <div className={classes('toggle-wrapper')}>
                            <Toggle toggleName={'analytics'} callback={setAnalytics} />
                            <span>Analytics</span>
                        </div>
                        <div className={classes('toggle-wrapper')}>
                            <Toggle toggleName={'personalisation'} callback={setPersonalisation} />
                            <span>Personalisation</span>
                        </div>
                        <div className={classes('toggle-wrapper')}>
                            <Toggle toggleName={'marketing'} callback={setMarketing} />
                            <span>Marketing</span>
                        </div>
                    </div>
                    <div className={classes('buttons')}>
                        <Button
                            text="Save settings"
                            extraClasses={['square', classes('button', 'secondary')]}
                            type="white"
                            onClick={() => {
                                savePreferences(analytics, personalisation, marketing)
                                setShow(false)
                            }}
                        />
                        <Button
                            text="Accept all"
                            extraClasses={['square', classes('button')]}
                            onClick={() => {
                                setAnalytics(true)
                                setPersonalisation(true)
                                setMarketing(true)
                                savePreferences(true, true, true)
                                setShow(false)
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CookieConsent
