'use client'

import styles from './ExpandedReview.module.scss'
import classNames from 'classnames/bind'
import Button from '@/components/Links/Button/Button'
import Markdown from 'markdown-to-jsx'
import SectionWrap from '@/components/Layout/SectionWrap/SectionWrap'
import Media from '@/components/Layout/Media/Media'
import Quote from '@/icons/quote-icon.svg'
import CheckCircle from '@/icons/check-circle.svg'

import { useRef } from 'react'
import Icon from '@/components/Elements/Icon/Icon'

const classes = classNames.bind(styles)

const ExpandedReview = ({ attributes }) => {
    const { blueText, title, content, points, image, buttons, styling } = attributes

    const buttonsRef = useRef([])

    return (
        <SectionWrap styling={styling} className={classes('container')}>
            <div className={classes('wrapper')}>
                <Quote className={classes('quote-icon')} />
                <span className={classes('name')}>{blueText}</span>
                <div className={classes('row')}>
                    <div className={classes('section-wrapper')}>
                        <div className={classes('header')}>
                            <span className={classes('title')}>{title}</span>
                            <div className={classes('content')}>
                                <Markdown children={content || ''} />
                            </div>
                        </div>
                        <div className={classes('image-and-points')}>
                            <div className={classes('points-wrapper')}>
                                {points && (
                                    <div className={classes('points')}>
                                        <div className={classes('vl')} />
                                        {points.map((point, i) => {
                                            return (
                                                <div className={classes('point')} key={`point-${i}`}>
                                                    <div className={classes('svg-wrap')}>
                                                        <CheckCircle />
                                                    </div>
                                                    <div className={classes('text')}>
                                                        <span>{point.point}</span>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}
                                {buttons && (
                                    <div className={classes('buttons')}>
                                        {buttons.map((button, i) => (
                                            <Button
                                                {...button}
                                                extraClasses={classes('button')}
                                                key={button.id}
                                                ref={(el) => (buttonsRef.current[i] = el)}
                                            />
                                        ))}
                                    </div>
                                )}
                            </div>

                            {image?.data && (
                                <div
                                    style={{ textAlign: 'center' }}
                                    className={classes('image-wrap')}
                                    onClick={() => {
                                        const videoButton = buttons.findIndex((button) => button.videoLink)
                                        if (videoButton != -1) {
                                            buttonsRef.current[videoButton].click()
                                        }
                                    }}
                                >
                                    <Media className={classes('smaller-image')} attributes={image.data.attributes} />
                                    <Icon icon="play" className={classes('play-icon')} />
                                </div>
                            )}
                        </div>
                    </div>
                    {image?.data && (
                        <div
                            className={classes('image', 'image-wrap')}
                            onClick={() => {
                                const videoButton = buttons.findIndex((button) => button.videoLink)
                                if (videoButton != -1) {
                                    buttonsRef.current[videoButton].click()
                                }
                            }}
                        >
                            <Media className={classes('image')} attributes={image.data.attributes} />
                            <Icon icon="play" className={classes('play-icon')} />
                        </div>
                    )}
                </div>
            </div>
        </SectionWrap>
    )
}
export default ExpandedReview
